import React, {useState,useEffect} from 'react';
import useCartContext from '../store/CartContext'
import { createBuyOrder} from '../data'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import {Link} from 'react-router-dom'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Animate from 'react-smooth'

const Formulario = () => {  
    const {cart, clearCart,calcPriceCart,removeFromCart} = useCartContext();      
    let orderID='';
    const [border,setBorder]=useState('border border-danger');
    const [enableButton,setEnableButton]=useState('outline-primary disabled');   
    const [showModal, setShowModal] =useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [showAlert, setShowAlert] =useState(false);    
   
    const [order, setOrder]=useState('')
    const [data, setData] = useState({
        name: '',
        mail: '',
        phone: '',
        address: ''
    })
     
    useEffect(()=>{
      if(((data.name && data.phone && data.address && data.mail && data.remail)!=='') && (data.mail === data.remail)) {setEnableButton('outline-primary enabled');}
        else {setEnableButton('outline-primary disabled');setShowAlert(false);}
      if(data.mail === data.remail){setBorder('border border-success')}
        else setBorder('border border-danger');
    },[data]);
    
    function handleBuy(){             
      handleShow()
      const itemsToBuy = cart.map((item) =>({
          name : item.name,
          cant : item.cant,
          price : item.price,
          id : item.id
        }))
      
        const buyOrder = {
          buyer:{
            name: data.name,
            email: data.mail,
            phone: data.phone, 
            address: data.address           
          },
          items:itemsToBuy,      
          total:calcPriceCart()
        }       
        orderID=createBuyOrder(buyOrder);
        orderID.then(value=>{
        setOrder(value);
        handleClose();  
        setShowAlert(true)      
        clearCart();  
         })
      } 
    
    const handleInputChange = (event) => {    
      setData({...data,[event.target.name] : event.target.value})
    }
    if (cart.length === 0 && order==='') return (    
      <div className='greeting textStyle'> 
      <Animate to="1" from="0" attributeName="opacity">  
      <h4>No hay productos en el Carrito</h4>        
      <Link to={`/eshop`}>
              <Button variant='outline-info'>Volver al Catálogo</Button>
      </Link>    
      </Animate>   
      </div>
    )
    if (!showAlert) return (
       <div className='greeting textStyle'>
        <Animate to="1" from="0" attributeName="opacity">
      <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <Modal.Body>
        <Modal.Title>Procesando su Pedido...</Modal.Title>
          Generando orden de compra, aguarde por favor... 
          <Spinner animation="border" variant="success" size="sm"/>
        </Modal.Body>
      </Modal>

         {cart.map(itemCart => {
        return <div key={itemCart.id}>              
<Table className='textStyle tableStyle' responsive size="sm">
        <thead>
    <tr>      
      <th style={{width:'8rem'}}>{itemCart.name}</th>      
      <th>Precio Unitario</th>
      <th>Cantidad</th>
      <th>Total del Producto</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><img variant="top" src={itemCart.url} alt='Imagen' style={{ width: '4rem' }} /></td>       
      <td>{itemCart.price}</td>
      <td>{itemCart.cant}</td>
      <td>{itemCart.cant * itemCart.price}</td>
      <td><Button style={{ backgroundColor: 'darkred' }} variant="danger" onClick={()=>removeFromCart(itemCart.id)}>X</Button></td>
    </tr>    
  </tbody>
</Table>
      </div>
    })}
        <br />
        <h3>Total de la Compra: ${calcPriceCart()}</h3>        
        <br /> 
         <h2>Ingrese sus Datos:</h2>
         <br />          
      <Form >
        <Form.Group className="mb-3 tableStyle">
          <Form.Label >Nombre y Apellido</Form.Label>
          <Form.Control className='border border-info' type="text" placeholder="Nombre y Apellido" onChange={handleInputChange} name="name"/>
          <Form.Label>Email</Form.Label>
          <Form.Control className={border} type="email" placeholder="Ingrese su email" onChange={handleInputChange} name="mail"/>
          <Form.Label>Ingrese Nuevamente su Email</Form.Label>
          <Form.Control className={border} type="email" placeholder="Ingrese nuevamente su email" onChange={handleInputChange} name="remail"/>
          <Form.Label>Teléfono</Form.Label>
          <Form.Control className='border border-info' type="text" placeholder="Ingrese su Teléfono" onChange={handleInputChange} name="phone"/>
          <Form.Label>Domicilio de Entrega</Form.Label>
          <Form.Control className='border border-info' type="text" placeholder="Domicilio de Entrega" onChange={handleInputChange} name="address"/>
          <Form.Text className="text-muted">
            Nota: No compartiremos sus datos.
          </Form.Text>
        </Form.Group>               
      </Form>
      <Button className={enableButton} variant="outline-primary" type="submit" size="lg" onClick={()=>handleBuy()}>      
      Finalizar Compra
      </Button>
      </Animate>
       </div>     
    );
    else return (
      <div className='greeting tableStyle'>
        <Animate to="1" from="0" attributeName="opacity">
        <Alert  variant='success'>¡ Compra confirmada ! Nº de Orden: {order}</Alert>                       
        <Alert  variant='success'>¡ Muchas gracias por su compra !</Alert>
        <Link to={`/`}>
            <Button variant="outline-primary">Volver al Catálogo</Button>
        </Link>
        </Animate>
      </div>
    )
}
export default Formulario;