import {Link} from 'react-router-dom'
import React, { useState } from 'react';
import {Nav,Button,CloseButton,Offcanvas,Navbar,Image} from 'react-bootstrap';
import Animate from 'react-smooth'
import menu from '../assets/images/menu.png'

function NavBar(){  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {setShowMenu(false); setShow(true)};  
  const [showMenu, setShowMenu] = useState(false);
  const handleCloseMenu = () => setShowMenu(false);
  const handleShowMenu = () => setShowMenu(true);  

    return(  
    <>  
    <Animate to="1" from="0" attributeName="opacity"> 
    <div className='navbar'>
      <h6 style={{color:'lightblue',textAlign:'left',margin:'0rem'}}>Moto ECG</h6>
    <Navbar  className='viewBar' bg="transparent" expand="md" variant='dark' >                      
        <Button className='viewButton' style={{width:'2rem', padding:'0', gap:'0'}} onClick={handleShowMenu} variant="outline-lightlight" >
        <Image src={menu} alt="image" width="30" height="20"/>
          </Button>        
        <Navbar.Collapse >
           <Nav  className="me-auto">
           <Link className="nav-link active" to="/" >Home</Link>
           <Link className="nav-link active" to="/eshop" >Tienda Virtual</Link>
            <Nav.Link className="nav-link active" onClick={handleShow}>Categorías</Nav.Link>            
           </Nav>
        </Navbar.Collapse> 
    </Navbar>
    </div>    
    </Animate>
    
    <Offcanvas placement='top' className='bg-dark opacity-75 ' show={showMenu} onHide={handleCloseMenu}>
      <Offcanvas.Header>   
                   
        </Offcanvas.Header>
        <Offcanvas.Body>  
          <div className="d-grid gap-2 text-center">
            <li><Link to="/" onClick={handleCloseMenu}><Button variant="outline-success">Home</Button></Link></li>
            <li><Link  to="/eshop" onClick={handleCloseMenu}><Button variant="outline-success">eShop</Button></Link></li>
            <li><Button onClick={handleShow} variant="outline-success">Categorías</Button></li>                
          </div>                                      
        </Offcanvas.Body>
        <br />  
        <div className="bg-dark p-3">
       <CloseButton variant="white" onClick={handleCloseMenu} />      
      </div>
      </Offcanvas>  

    <Offcanvas placement='end' className='bg-dark opacity-75 w-auto' show={show} onHide={handleClose}>
      <Offcanvas.Header>   
      <div className="bg-dark p-3">
       <CloseButton variant="white" onClick={handleClose} />      
      </div>             
        </Offcanvas.Header>
        <Offcanvas.Body>  
          <div className="d-grid gap-3 text-center">
            <li><Link to="/categoria/components" onClick={handleClose}><Button variant="outline-info">Componentes</Button></Link></li>
            <li><Link  to="/categoria/peripheral" onClick={handleClose}><Button variant="outline-info">Periféricos</Button></Link></li>
            <li><Link to="/categoria/audio" onClick={handleClose}><Button variant="outline-info">Audio</Button></Link></li>
            <li><Link  to="/categoria/video" onClick={handleClose}><Button variant="outline-info">Video</Button></Link></li>            
            <li><Link to="/downloads" onClick={handleClose}><Button variant="outline-info" >Descargas</Button></Link></li>    
          </div>                                      
        </Offcanvas.Body>
      </Offcanvas>  
      
    </>
    )
}
export default NavBar