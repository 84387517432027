import {React,useState} from 'react';
import {Card,Spinner,Button} from 'react-bootstrap';
import cardImage from '../assets/images/cielo4.jpg';
import {getUrlFiles} from '../data/index';
import Animate from 'react-smooth';

function Downloads(){   
  const [catalogo, setCatalogo]=useState(undefined); 
  function downloadFile(file){ 
    getUrlFiles(file).then(url=>{window.open(url);setCatalogo(url)});
    setCatalogo('wait');
  }
  
  if(catalogo===undefined){   
    return (
        <div className='greeting item' >
          <Animate to="1" from="0" attributeName="opacity">
        <Card onClick={()=>downloadFile('files/Catálogo Meschini Computación.pdf')} className='bg-transparent cardSize textStyle' border="info" > 
        <Card.Header>Catálogo</Card.Header>
        <Card.Body>  
        <Button  variant="outline-lightlight" ><Card.Img src={cardImage} /></Button>    
        <h5 style={{textAlign: 'center', height:'1rem'}}>Descargar Catálogo</h5>
        </Card.Body>                  
        </Card>    
        </Animate>           
        </div>        
      )
  }
  if (catalogo==='wait'){ 
    return(
      <div className='greeting textStyle'>       
      <Animate to="1" from="0" attributeName="opacity">            
          <Spinner animation="border" variant="info" />
          <h6>Esperando conexión...</h6>
      </Animate> 
      </div>
    )
  } else {         
     return (
      <div className='greeting textStyle'>
      <h5>¡Muchas gracias por descargar el Catálogo!</h5>  
      <br />
      <Button onClick={()=>setCatalogo(undefined)} variant='outline-info'>Regresar a Descargas</Button>    
      </div>
     )
    }
}
export default Downloads