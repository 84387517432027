import React, {useState,useEffect} from 'react';
//import articlesData from "../data/articlesData";
import { getAllItems as getData, getItemsByCategory } from '../data/index';
import { Card,Col,Row,Spinner,Button,Modal} from 'react-bootstrap';
import {useParams,Link} from 'react-router-dom';
import useCartContext from '../store/CartContext';
import ItemCount from './ItemCount';
import Animate from 'react-smooth'

  function ItemListContainer(props) {  
  const {categoryid}=useParams();
  const [articles, setArticles]=useState([]);  
  const [detailsArticle, setDetailsArticle]=useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => {setShow(false);}                             
  const handleShow = () => {setIsInCart(false);setShow(true)};
  const [isInCart, setIsInCart]= useState(false)
  const {addToCart}=useCartContext(); 

  useEffect(()=>{
    if (categoryid===undefined){
      setArticles([]);
      setDetailsArticle([]);      
    getData().then((data)=>{
      setArticles(data);
    });
  }
  else {
    setArticles([]);
    setDetailsArticle([]);
    getItemsByCategory(categoryid).then((data)=>{
      setArticles(data);
    });
  }
  },[categoryid]);  

  function showCode (code){    
    articles.forEach(element => {
      if(element.id===code){setDetailsArticle(element);handleShow()}
    });
  }

  function onAdd(count){    
    setIsInCart(true);
    addToCart(detailsArticle,count);
    //console.log("agregado al cart:",detailsArticle,count);
  }

  function waitForConnection(){
    if(categoryid===undefined) getData().then((data)=>{setArticles(data);});      
    else getItemsByCategory(categoryid).then((data)=>{setArticles(data);});
  }

  if (articles.length !== 0){         
    return (   
      <Row className="greeting">
    <div>
      <Animate to="1" from="0" attributeName="opacity">
          <h3 className='textStyle'>Bienvenidos a la Tienda Virtual</h3> 
       <div className='item'> 
          {articles.map((article)=>            
        <div key={article.id}>   
        <Col>    
          <Card onClick={()=>showCode(article.id)} className='bg-transparent cardSize textStyle' border='info'  >
           <Card.Header>{article.name}</Card.Header> 
            <Card.Body>   
            <Button  variant="outline-lightlight" >
            <Card.Img src={article.url} alt="image" width="75" height="75"/>
            </Button>    
            <h6 style={{textAlign: 'center', height:'1rem'}}>{article.details}</h6> 
            </Card.Body>  
          </Card> 
          </Col>
        </div>
        )}        
       </div>
       </Animate>
    </div>    
    
    <Modal centered show={show} onHide={handleClose}>        
        <Modal.Body>    
          <h5>Detalles del Producto</h5>
          <Animate to="1" from="0" attributeName="opacity">      
        <Card className='bg-transparent '>
          <Card.Header>{detailsArticle.name}</Card.Header>          
            <Card.Img variant="top" src={detailsArticle.url}/> 
            <Card.Body> 
            <h6>$: {detailsArticle.price}</h6>
            <h6>Stock: {detailsArticle.stock}</h6>
            <h6>Detalles: {detailsArticle.details}</h6>    
            {isInCart?
            <Link to={`/Cart`}>
            <Button variant="outline-dark" size='md'>Ir al Carrito</Button>
            </Link>
            :
            <div>
             <h5>Compra tus items</h5>
             <ItemCount onAdd={onAdd} stock={detailsArticle.stock} initial={1}/>
            </div>
          }     
            </Card.Body>                       
          </Card>
          </Animate> 
          <Button variant="outline-dark" size='sm' onClick={handleClose}>
            Cerrar
          </Button>  
        </Modal.Body>        
      </Modal>
    </Row>
    )   
  }
  else {    
      waitForConnection();
    return (   
      <div className='greeting textStyle'>       
      <Animate to="1" from="0" attributeName="opacity">            
          <Spinner animation="border" variant="info" />
          <h6>Esperando conexión...</h6>
      </Animate> 
      </div>
    )
  }  
  
}

export default ItemListContainer