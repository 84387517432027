import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'

function ItemCount(props) {
  const [count, setCount]=useState(props.initial);
  
  function handleAdd(){
   if(count<props.stock) setCount(count+1);
  }
  function handleSubstract(){
   if(count>1) setCount(count-1);
  }
  
    return (
    <div>        
        <div>
            <Button variant="outline-dark" onClick={handleSubstract}>-</Button>
            <span>  {count}  </span>
            <Button variant="outline-dark" onClick={handleAdd}>+</Button>
        </div>
        <br />
        <div>
            <Button variant="outline-dark" size='md' onClick={()=>props.onAdd(count)}>Agregar al Carrito</Button>
        </div>
        <div>
        </div>
    </div>
  )
}

export default ItemCount