import React, {useState} from "react";
import {Carousel,Spinner}from 'react-bootstrap';
import Animate from 'react-smooth';
import {getListUrl} from '../data/index';

function Home(){  
  const [slides,setSlides]=useState([]);
  //console.log("render");
     if(slides.length===0){ 
      getListUrl('images').then(resp=>{      
        setTimeout(() => { //le dá tiempo a 'resp' de adquirir su valor correcto.                    
          setSlides(resp);
          if(resp.length<4) setSlides([]);
        }, 1000);      
        
      })        
     }     
  if(slides.length===4){  
   // console.log(slides[0].length)
    return (
      <div className="greeting testStyle">
        <Animate to="1" from="0" attributeName="opacity"> 
        <Carousel className="slider-container" interval={1200}>
      {slides.map((slide,i)=>{         
        let position=87;
        do {
          if(slide[position]==='.')break;
          position++;
        } while (position<slide.length);        
        return(                              
        <Carousel.Item key={i} className="textStyle">         
        <Carousel.Caption>
          <h3>{slide.slice(87,position)}</h3>
          <p></p>
        </Carousel.Caption>
        <img
          className="d-block w-100"
          src={slide}
          alt="Esperando Conexión"                    
        />
      </Carousel.Item>          
        )}
      )}
      </Carousel>
      </Animate>
      </div>
  )}
  else {
    return (   
      <div className='greeting textStyle'>       
      <Animate to="1" from="0" attributeName="opacity"> 
          <Spinner animation="border" variant="info" />
          <h6>Esperando conexión...</h6>
      </Animate> 
      </div>
    )
  }
}
export default Home
