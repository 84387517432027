import React from 'react'
import useCartContext from '../store/CartContext'
import {Link} from 'react-router-dom'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Animate from 'react-smooth'

function CheckOut() {   
    const {cart,calcPriceCart,removeFromCart} = useCartContext();          
    if (cart.length === 0) return (    
      <div className='greeting textStyle'>  
      <Animate to="1" from="0" attributeName="opacity">  
      <h4>No hay productos en el Carrito</h4>        
      <Link to={`/eshop`}>
              <Button variant='outline-info'>Volver al Catálogo</Button>
      </Link> 
      </Animate>     
      </div>
    )
    if (cart.length !== 0) {
         return (
         <div className='greeting'>
          <Animate to="1" from="0" attributeName="opacity">
           <h2 className='textStyle'>Confirmar Compra</h2>
           {cart.map(itemCart => {
        return <div key={itemCart.id}>              
<Table responsive size="sm"  className='textStyle tableStyle'>
  <thead>
    <tr>      
      <th style={{width:'8rem'}}>{itemCart.name}</th>      
      <th>Precio Unitario</th>
      <th>Cantidad</th>
      <th>Total del Producto</th>
    </tr>    
  </thead>
  <tbody>  
    <tr>
      <td><img variant="top" src={itemCart.url} alt='Imagen' style={{ width: '4rem' }} /></td>            
      <td>{itemCart.price}</td>
      <td>{itemCart.cant}</td>
      <td>{itemCart.cant * itemCart.price}</td>
      <td><Button style={{ backgroundColor: 'darkred' }} className='btn-danger' onClick={()=>removeFromCart(itemCart.id)}>X</Button></td>      
    </tr>
    </tbody>
</Table>
      </div>
    })}
        <h3 className='textStyle'>Total de la Compra: ${calcPriceCart()}</h3>        
        <br />  
        <Link to={`/dataform`}>
            <Button variant="outline-success">Cargar Datos y Finalizar</Button>
        </Link>   
        </Animate>
        </div>
      )
    }          
}

export default CheckOut