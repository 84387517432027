import widgetCart from '../assets/images/carrito3.png'
import useCartContext from '../store/CartContext'
import { Link } from 'react-router-dom'
import Badge from 'react-bootstrap/Badge'

function CartWidget() {
  const {cantInCart} = useCartContext();
  return (
    <div className='cartWidget'>
       <Link to={`/Cart`}>         
         <img src={widgetCart} alt="Carrito" width="30" height="30"/>                   
       </Link>
       <Badge bg="primary">{cantInCart()}</Badge>
    </div>    
  )
}

export default CartWidget