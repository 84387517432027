import React from 'react'
import useCartContext from '../store/CartContext'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Animate from 'react-smooth'

function CartView() {    
    const {cart, removeFromCart,clearCart,calcPriceCart,addToCart} = useCartContext();    
    //console.log("cart",cart);  

    function updateCart(item,op){  
      if((cart.some(value => item.id === value.id))){                     
        if(op==='add' && item.cant<item.stock)addToCart(item,1);
        if(op==='subst' && item.cant>1)addToCart(item,-1)
      }  
    }
    if (cart.length ===0) return (   
       
    <div  className='greeting textStyle'>    
    <Animate to="1" from="0" attributeName="opacity">
    <h4>No hay productos en el Carrito</h4>        
    <Link to={`/eshop`}>
            <Button variant="outline-info">Volver al Catálogo</Button>
    </Link> 
    </Animate>     
    </div>
  )
  else {
    return <div className='greeting textStyle'>  
    <Animate to="1" from="0" attributeName="opacity"> 
    <h2>Carrito de Compras</h2>   
     {cart.map(itemCart => {      
        return <div key={itemCart.id}>              
 <Table className='tableStyle' responsive size="sm" >
  <thead>
    <tr>      
      <th style={{width:'8rem'}}>{itemCart.name}</th>
      <th>P. Unitario</th>
      <th>Cantidad</th>
      <th>Total</th>
    </tr>
  </thead>
  <tbody> 
    <tr>
      <td><img  src={itemCart.url} alt='Imagen' style={{ width: '4rem'}} /></td>            
      <td>{itemCart.price}</td>
      <td><Button style={{ backgroundColor: 'darkblue', width: '1rem'}} className='btn-primary' onClick={()=>updateCart(itemCart,'subst')} >-</Button>
        <span>  {itemCart.cant}  </span>
      <Button style={{ backgroundColor: 'darkblue', width: '1rem'}} className='btn-primary' onClick={()=>updateCart(itemCart,'add')} >+</Button>     
      </td>
      <td>{itemCart.cant * itemCart.price}</td>
      <td><Button style={{ backgroundColor: 'darkred' , width: '2rem' }} className='btn-danger' onClick={()=>removeFromCart(itemCart.id)}>X</Button></td>
    </tr> 
  </tbody>
</Table>
      </div>
    })}
    <br />
    <h4 className='textStyle'>Total del Carrito: ${calcPriceCart()}</h4>      
    <Link to={`/CheckOut`}>
    <Button size="lg" variant="outline-success">Ir al CheckOut</Button>
    </Link> 
    <br />
    <br />     
    <Button className='textStyle' size="sm" variant="outline-danger" onClick={clearCart}>Vaciar Carrito</Button>
    </Animate> 
    </div>
  }
}

export default CartView