import './App.css';
import ItemListContainer from './components/ItemListContainer';
import CartView from './components/CartView'
import NavBar from './components/NavBar';
import CartWidget from './components/CartWidget';
import LogoTienda from './components/LogoTienda';
import {BrowserRouter,Route,Routes } from 'react-router-dom';
import {CartContextProvider} from './store/CartContext'
import CheckOut from './components/CheckOut';
import DataForm from './components/DataForm'
import Downloads from './components/Downloads';
import Home from './components/home';

function App() {  
  return (    
    <div className="App">
    <CartContextProvider>
     <BrowserRouter>  
     <LogoTienda/> 
     <NavBar/>
      <CartWidget/>
      <Routes>
        <Route path="/" element={<Home/>}></Route> 
        <Route path="/eshop" element={<ItemListContainer/>}></Route>            
        <Route path="/categoria/:categoryid" element={<ItemListContainer title="Productos disponibles: "/>}></Route>
        <Route path='/Cart' element={<CartView/>}></Route>
        <Route path='/checkOut' element={<CheckOut/>}></Route>
        <Route path='/dataform' element={<DataForm/>}></Route>
        <Route path='/downloads' element={<Downloads/>}></Route>
      </Routes>   
     </BrowserRouter>  
     </CartContextProvider>                 
    </div>
  );
}

export default App;
